<template>
  <header>
    <b-navbar class="px-5" toggleable="sm">
      <b-navbar-brand to="/dashboard"
        ><strong>citizen</strong>portal</b-navbar-brand
      >

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-5" v-if="userRole != 'Unauthorized'">
          <b-nav-item v-if="userRole == 'SysAdmin'" to="/cities"
            >Cities</b-nav-item
          >
          <b-nav-item to="/widgets">Widgets</b-nav-item>
          <b-nav-item to="/users">Users</b-nav-item>
          <b-nav-item to="/settings">Settings</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto align-items-center right-items">
          <b-nav-item-dropdown
            class="help-dropdown"
            no-caret
            menu-class="help-info-dropdown"
            right
          >
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <BIconQuestionCircle class="pt-1 help-icon" />
            </template>
            <b-dropdown-item
              class="theme-font-medium"
              :href="help.url"
              :title="help.helpText"
              target="_blank"
              v-for="(help, index) in helpInfoList"
              :key="index"
              >{{ help.name }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-dropdown-text class="menu-right-divider"></b-dropdown-text>
          <b-nav-item-dropdown
            class="user-dropdown"
            no-caret
            menu-class="user-profile-dropdown"
            right
          >
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <span v-if="userProfile" class="user-dropdown-initials-btn">
                <b-icon-person-fill
                  v-if="!userProfile.firstname && !userProfile.surname"
                ></b-icon-person-fill>
                <template v-if="userProfile.firstname">{{
                  userProfile.firstname.charAt(0)
                }}</template
                ><template v-if="userProfile.surname">{{
                  userProfile.surname.charAt(0)
                }}</template>
              </span>
              <BIconChevronDown class="pt-1" scale="0.65" />
            </template>
            <b-dropdown-text v-if="userProfile" class="username-item">
              <div>
                <span class="user-profile-initials">
                  <b-icon-person-fill
                    v-if="!userProfile.firstname && !userProfile.surname"
                  ></b-icon-person-fill>
                  <template v-if="userProfile.firstname">{{
                    userProfile.firstname.charAt(0)
                  }}</template
                  ><template v-if="userProfile.surname">{{
                    userProfile.surname.charAt(0)
                  }}</template>
                </span>
              </div>
              <div class="username">
                {{ userProfile.firstname + " " + userProfile.surname }}
              </div>
              <div class="user-email">{{ userProfile.email }}</div>
            </b-dropdown-text>
            <b-dropdown-text class="border-0">
              <b-button
                @click="signOut"
                v-activeBlur
                class="px-4 theme-font-bold"
                variant="outline-primary"
                >Sign out</b-button
              ></b-dropdown-text
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>
<script>
import { mapState } from 'vuex'
import { HELP_INFO_LIST } from '../../utilities/constants'
import {
  BIconPersonFill,
  BIconChevronDown,
  BIconQuestionCircle
} from 'bootstrap-vue'

export default {
  name: 'Header',
  data () {
    return {
      helpInfoList: HELP_INFO_LIST
    }
  },
  computed: {
    ...mapState({
      userRole: (state) => state.common.userRole,
      userProfile: (state) => state.common.userProfile
    })
  },
  components: {
    BIconPersonFill,
    BIconChevronDown,
    BIconQuestionCircle
  },
  methods: {
    signOut () {
      this.$store.dispatch('common/signOut')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
