<template>
  <div class="sub-header">
    <b-navbar class="px-5" toggleable="sm" type="dark" variant="dark">
      <div v-if="selectedTenant.length">
        <span class="city"
          ><BIconGeoAlt class="mr-2"/>{{ selectedTenant.length > 1 ? "Cities" : "City" }}:
          <span v-for="(tenant, index) in selectedTenant" :key="tenant.tenantID"
            >{{ tenant.tenantName }}

            <template v-if="index + 1 != selectedTenant.length"
              >,
            </template></span
          ></span
        >
        <span v-if="availableTenantsList.length > 1">
          <span class="divider"> | </span>
          <a
            href="javascript:void(0)"
            @click="changeCity"
            class="change-city-link"
            >Change City</a
          >
        </span>
      </div>
    </b-navbar>
    <!-- Change city modal -->
    <b-modal
      v-model="displayChangeCityModal"
      title="Change city"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      @hide="resetChangeCityModal"
    >
      <form @submit.prevent="onSubmitChangeTenant">
        <b-form-row v-if="availableTenantsList.length">
          <b-col cols="12" class="mb-3">
            <multiselect
              v-model="changedTenant"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              track-by="tenantID"
              label="tenantName"
              placeholder="Select city"
              :options="availableTenantsList"
              :searchable="false"
              @select="onSelectTenant"
            >
            </multiselect>
            <div class="text-danger" v-if="!changedTenant.length">
              City required.
            </div>
          </b-col>
          <b-col class="mb-4">
            <b-button
              type="submit"
              v-activeBlur
              variant="primary"
              :disabled="!changedTenant.length"
              >Select</b-button
            >
            <b-button
              class="ml-3"
              v-activeBlur
              variant="outline-secondary"
              @click="resetChangeCityModal"
              >Cancel</b-button
            >
          </b-col>
        </b-form-row>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import { BIconGeoAlt } from 'bootstrap-vue'
export default {
  name: 'Subheader',
  components: {
    Multiselect,
    BIconGeoAlt
  },
  data () {
    return {
      displayChangeCityModal: false,
      changedTenant: []
    }
  },
  computed: {
    ...mapState({
      availableTenantsList: (state) => state.common.availableTenantsList,
      selectedTenant: (state) => state.common.selectedTenant,
      userRole: (state) => state.common.userRole
    })
  },
  methods: {
    changeCity () {
      this.displayChangeCityModal = true
      this.changedTenant = this.selectedTenant
    },
    onSelectTenant (tenant) {
      if (tenant.tenantID === 0) {
        setTimeout(() => {
          this.changedTenant = [tenant]
        }, 200)
      } else {
        const index = this.changedTenant.findIndex(
          (tenant) => tenant.tenantID === 0
        )
        if (index > -1) {
          setTimeout(() => {
            this.changedTenant = this.changedTenant.filter(
              (tenant) => tenant.tenantID !== 0
            )
          }, 200)
        }
      }
    },
    resetChangeCityModal () {
      this.displayChangeCityModal = false
    },
    onSubmitChangeTenant () {
      this.$store.dispatch('common/updateAdminTenants', this.changedTenant)
      this.displayChangeCityModal = false
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>
